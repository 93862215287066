@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'LiAdorNoirrit';
    src: url('./assets/LiAdorNoirrit.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'ClasicUnicode';
    src: url('./assets/ClasicUnicode.otf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

body{
    font-family: 'LiAdorNoirrit';
}

.glow{
    -webkit-box-shadow: 0px 0px 42px 1px rgba(35,197,94,1);
-moz-box-shadow: 0px 0px 42px 1px rgba(35,197,94,1);
box-shadow: 0px 0px 42px 1px rgba(35,197,94,1);
}

.animate__animated.animate__zoomIn {
    --animate-duration: 0.3s;
  }



  @keyframes rotate {
    0% {
      transform: rotate(0deg); /* Start at 0 degrees rotation */
    }
    100% {
      transform: rotate(360deg); /* End at 360 degrees (full rotation) */
    }
  }
  
  .rotating-image {
    animation: rotate 45s linear infinite; /* Rotate over 5 seconds, infinitely */
  }


  @layer base{
    .font-heading{
        font-family: 'ClasicUnicode';
    }
  }